.card-container {
    background-image: url(https://www.ppsthane.com/wp-content/uploads/2020/06/Homepage-FAQ-Perfect-Pollucon-Services-min.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.card {
    max-width: 554px;
    width: 100%;
    background: #eaf2fa;
    padding: 50px;
    text-align: left;
    margin-top: 20px;
    

}



/* .Middle container {
    max-width: 554px;
    width: 100%;
    background: #eaf2fa;
    padding: 50px;
    text-align: left;
}
 */

.Frequently {
    font-size: 3.125rem;
    line-height: 3.5rem;
    font-weight: 700;
    margin-bottom: 0;
    color: #41424E;
    text-transform: none;
}
.Monitoring {
    font-size: 2rem;
    line-height: 1rem;
    font-weight: 100;
    margin-bottom: 0;
    /* color: #41424E; */
    text-transform: none;
}


.Description {
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 20px;
    font-weight: 400;
}
.enabled {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.enabled-class {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.tab1 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.tab2 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

