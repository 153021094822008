/* Navbar.css */
.navb {
    background: rgb(136, 213, 20);
    /* background-color:#888888; */
    display: flex;
    /* padding-left: 120px;
    padding-right: 120px; */
    margin-left: 120px;
    margin-right: 100px;

   
    /* justify-content: center; Center horizontally */
    justify-content: center; /* vertically */
    height: 70px !important;
    position: absolute;
    z-index: 9;
    

   
  }

  .nav-container {
    /* padding-right: 120px;
    padding-left: 120px; */
    position: relative;
    width: 100%;
    color: #888888;
    font: 19px;
    background-color: #FFFFFF;

    
  }
  
  .nav-list {
    list-style: none;
    padding: 0;
    display: flex;
    /* margin-top: 40px; */
    
  }

  .item {
    width: 100%;
    height: 100vh !important;
    /* position: absolute; */
    margin-top: 35px;

  }
  
  .nav-item {
    /* margin: 0 15px; */
    /* margin-right: 10px; */
   
    font-size: 16px;
    padding-left: 48px;
    padding-right: 47px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding-top:5px ;
    
    
    
  }
  
  .nav-item a {
    text-decoration: none;
    color: white;
    font-size: 19px; 
    /* margin-right: 40px;/* Increase the font size as needed */
  } 
  
  .nav-item a:hover {
    color: #ff5733; /* Change the color on hover */
  }
  .carousel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headers {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-right: 100px;
    margin-left: 140px;
    /* color: #00cc00; */
    
    /* font-weight: bold; */
    /* font-size: 50px; */
}


.header {
    font-size: 25px; /* Adjust the font size as needed */
    /* margin: 0 20px; */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33%;
    /* padding-right: 2rem; */
    
    
}

.header-number {
  /* font-size: 50px;
  color: #80D100;
  padding: 64px;
  height: 2px; */
  font-size: 3.125rem; 
    color:#80D100;
    font-family: 'Hind', sans-serif;
    height: 2.4rem;
    line-height: 1.5;
    
    font-weight: 600;

  
}
.header-description {
  color: #888888; /* Change the color to your preference */
  font-size: 16px; /* Adjust the font size as needed */
  /* margin-top: 20px; Add margin between the number and the description */
  /* margin-bottom: 50px;
  margin-top: 2px; */
  flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33%;
    padding-right: 2rem;
}

.header-text {
  color: #41424E;
  font: 22px;
  font-family: 'Hind', sans-serif;
}

/* Style for the number */
/* .header-number {
  font-size: 19px;
  color: green; /* Change the color as needed */
  /* font-weight: bold; */
/* } */ 
/* .header {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: 20px;
  color:gray;
  /* font-weight: bold; */
  /* margin-top: 50px; */
  /* font-size: 50px; */
/* } */ 

/* ... Your existing styles ... */

/* .nav-container {
  position: relative;
} */

/* Add these styles to your existing stylesheet */

.nav-item {
  position: relative;
}

.services-box {
  position: absolute;
  top: 100%; /* Position the box below the nav item */
  left: 0;
  display: none; /* Hide the box by default */
  background-color: #fff; /* Background color for the box */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Optional: Add a shadow */
  z-index: 1000; /* Set a high z-index to ensure it appears above other elements */
  width: 400px;
  margin-top: 10px;
}

.nav-item:hover .services-box {
  display: block; 
}

.services-list {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.services-list li {
  padding: 8px 0;
  /* border-bottom: 1px solid #ccc; Optional: Add a border between list items */
  color: black;
}

/* Customize the styles as needed */
