.ecb-Box {
    text-transform: capitalize;
    padding-right: 0.5rem!important;
    border-right: 1px solid rgba(0,0,0,0.1);
      height: 45px;
      line-height: 45px;
      color: #888;
      font-size: 20px;
    
  }

  .ecb-Boxone {
    text-transform: capitalize;
    padding-right: 0.5rem!important;
    border-right: 1px solid rgba(0,0,0,0.1);
      height: 45px;
      line-height: 45px;
      color: #888;
      font-size: 20px;
      padding-left: 8px;
  }
  