/* About.css */

.about-container {
    
    /* max-width: 10000px; Adjust as needed */
    /* margin: 0 auto; */
    margin-left: 120px;
    margin-right: 120px;
    color: #41424E;
    display: flex;
    flex-direction: row;
    /* border-top-right-radius: 0;
    border-color: aqua; */
  }

  .item1 {
    background-color: green !important;
    color: white !important;
    margin-top: 45px;
    text-align: left !important;
    height: 110px;
    font-size: 20px !important;
    /* padding-left: 45px !important; */
    padding-right: 35px !important;
    /* padding-top: 78px !important; */
    padding-bottom:  67px !important;
    
  }
  .hand {
    margin: 30px !important;
    font-size: 30px !important;
    /* padding-bottom: 20px !important; */
    
    padding-left: 25px;
    margin-bottom: 30px;
   
    
   
    
    

    
  }
  .itme2 {
    background-color: blue !important;
    color: white !important;
    margin-top: 50px;
    text-align: left !important;
    height: 110px;
    font-size: 20px !important;
    /* padding-left: 45px !important; */
    padding-right: 35px !important;
    /* padding-top: 78px !important; */
    padding-bottom:  67px !important;
    
    
    
  }

  .Person {
    margin: 30px !important;
    font-size: 25px !important;

  }
  .item3 {
    background-color: pink !important;
    color: white !important;
    margin-top: 50px;
   
     text-align: left !important;
    height: 110px;
    font-size: 20px !important;
    
  }

  .icon {
    /* height: 1.5rem; */
    /* width: 1.5rem; */
    /* background-color: red; */
    /* padding: 4px; */
    size-adjust: 45px;
   
   

    
  }
  
  .text-container {
    /* flex: 1; */
    width: 100%;

    
    margin: 16px; /* Adjust as needed */
    /* margin-left: 2px; */
    font: 17.5px;
    color: #727888;


  }
  .About-class {
    /* font-size: 50px;
   
    
     */
     font-size: 3.125rem;
     margin-top: 50px;
    /* line-height: 3.5rem; */
    font-weight: 700;
    /* margin-bottom: 0; */
    color: rgb(17, 199, 17);
    
    /* text-transform: none; */
    /* text-decoration: none; */
}

  /* } */
    
 

  .pollucon-class {
    font-size: 32px;
    /* margin: 20px; */
    color: #41424E;
  }
  
  .more-class {
    color: rgb(131, 208, 14) !important;
  }
  .button-class {
   display: block;
   margin-top: 20px;
  background-color: rgb(131, 212, 9) !important;
  }
  .about-image {
    flex: 1;
    max-width: 100%; /* Display the image in 40% of the container */
    height: 360px;
    margin-top: 73px;
    margin-left: auto; /* Move the image to the right side */
    border-left: 2px solid transparent; /* Hide left border */
  border-right: 2px solid #ddd; /* Display right border */
  border-top: 2px solid transparent; /* Hide top border */
  border-bottom: 2px solid transparent; /* Hide bottom border */
  border-radius: 8px; 
 /* background:  linear-gradient(109.6deg, rgb(72, 200, 160) 11.2%, rgb(32, 40, 48) 91.3%); */
 background-color: #f5f5f5;

    
  }

/* .image-class {
  width: 40%;
  padding-left: 2rem;
  text-align: left;

  
  
  
} */


  
  .about-container h1 {
    margin-bottom: 20px;
    /* text-decoration: underline; */
  }
  
  .about-container p {
    /* max-width: 100%; */
    color: gray;
    font-size: 20px;
    text-align: justify;
    /* margin-left: 0; */
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  /* Add any additional styling as needed */
  .paragraph class {
    font-size: 1.1rem !important;
    font-weight: 400 !important;
    color: #727888 !important;
    line-height: 1.8rem !important;

  }
  