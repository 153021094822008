.choose {
   font-size: 85px !important;
   text-align: center;
   margin-top: 55px;
   /* background-color: brown; */



}
.intemA {
   background-color: rgb(231, 197, 197) !important;
   /* height: 50px;
   width: 45px; */
   /* margin-bottom: 50px; */
   height: 60px;
   /* margin-left: 120px;
   margin-right: 120px; */
   text-align: center !important;
  
   padding-right: 35px !important;

}
.intemB {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;

}
.intemc {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;
   text-align: center !important;
   padding-right: 25px !important;
  
  

}
.intemD {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;
   text-align: center !important;
  

}
.intemE {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;
   text-align: center !important;

}
.intemF {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;

}
.intemG {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;
   text-align: center !important;

}
.intemh {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;

}
.intemI {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;
   text-align: center;

}
.intemJ {

   background-color: rgb(231, 197, 197) !important;
   height: 60px;
   text-align: center;
}
.intemk {
   background-color: rgb(231, 197, 197) !important;
   height: 60px;

}


