/* YourComponent.css */

/* Style for the navbar container */
.navbar {
   display: flex;
   align-items: center;
  
  padding-top: 10px;
  padding-left: 120px;
  color: #888888;
  /* background-color: green; */
  /* border-inline-start: 1px; */
  /* margin-top: 1px;
  margin-right: 3px; */
  /* background: linear-gradient(109.6deg, rgb(72, 200, 160) 11.2%, rgb(32, 40, 48) 91.3%); */
  /* background-color: #f5f5f5;
  font-size: 19px; */ 
  width: 100%;
  float: left;
  background: #f5f5f5;
  position: relative;
  z-index: 10;
  height: 45px ! important;
  align-items: center;
  /* padding-right: 120px !important; */
  padding-right: 120px;
  width: 85%;

}

/* Style for the logo */
.logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
  /* margin-top: 2px; */
}

.ecb-Box {
  text-transform: capitalize;
  padding-right: 0.5rem!important;
  border-right: 1px solid rgba(0,0,0,0.1);
    height: 45px;
    line-height: 45px;
    color: #888;
    font-size: 20px;
  
}


/* Style for the office hours paragraph */
.ecb-Boxone {
  text-transform: capitalize;
  padding-right: 0.5rem!important;
  border-right: 1px solid rgba(0,0,0,0.1);
    height: 45px;
    line-height: 45px;
    color: #888;
    font-size: 20px;
    padding-left: 8px;
}

/* Style for the vertical line */
h1 {
  position: relative;
  margin-top: 2px;
}

.line {
  position: absolute;
  /* top: 0;
  bottom: 0; */
  width: 2px; /* Adjust the line thickness as needed */
  background-color: #000; /* Change the color as needed */
  left: 27%; /* Adjust the position as needed */
  transform: translateX(-50%);
  z-index: -1; /* Place the line behind the text */
}

/* Add CSS styles to create the vertical line effect */
.navbar h1 {
  position: relative;
}

/* .navbar h1::before {
  content: "";
  position: absolute;
  top: 0; */
  left: 105%; /* Adjust the position as needed */
  transform: translateX(-50%);
  height: 100%; /* This makes the line go from top to bottom */
  width: 2px; /* Adjust the line thickness as needed */
  background-color:black; /* Change the color as needed
} */

/* Style for the social media icons container */
/* Style for the social media icons container */
.social-icons {
  display: flex;
  align-items: center;
  /* margin-left: auto; Push icons to the right */

  padding-left: 120px;
 
}

/* Style for individual social media icons */
.social-icon {
  /* font-size: 1rem; /* Adjust the icon size as needed */
  /* color: #ebeff1; Change the color to match your design */
  /* margin-left: 10px; Adjust the spacing between icons as needed */ 
  /* text-decoration: none; Remove underlines if they are anchor links */
  padding: 0px 15px;
  display: inline-block;
  border-right: 1px solid rgba(0,0,0,0.05);
  color: #c3c3c3;
  height: 45px;
  line-height: 48px;
  font: 19px;
 


}
.social-icon s {
  padding: 0px 15px;
  display: inline-block;
  border-right: 1px solid rgba(0,0,0,0.05);
  /* color: #c3c3c3 ; */
  height: 45px;
  line-height: 48px;
  color: rgb(153, 231, 36)!important;

  
  

}

.icon special {
  padding-right: 120px;
 
}



/* Style for hover effect on social media icons (optional) */
.social-icon:hover {
  color: #0e76a8; /* Change the color when hovering */
  transform: scale(1.2); /* Adjust the scaling effect as needed */
}
/* Style for the individual social media icons */
.social-icon {
  font-size: 2rem; /* Adjust the icon size as needed */
  text-decoration: none; /* Remove underlines if they are anchor links */
}

/* Style for the Facebook icon */
.social-icon.facebook {
  color: #8888; /* Set the color for Facebook */
}

/* Style for the Twitter icon */
.social-icon.twitter {
  color: #888; /* Set the color for Twitter */
}

/* Style for the Instagram icon */
.social-icon.instagram {
  color: #888; /* Set the color for Instagram */
}

/* Style for the YouTube icon */
.social-icon.youtube {
  color: #888; /* Set the color for YouTube */
}

/* Style for the YouTube icon */
.social-icon.Searchbar {
  color: #888; /* Set the color for Searchbar */
}
/* Style for the social media icons container */
.social-icons {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push icons to the right */
}

/* Style for individual social media icons */
.social-icon {
  font-size: 2rem; /* Adjust the icon size as needed */
  text-decoration: none; /* Remove underlines if they are anchor links */
}

/* Add a margin to create spacing between icons */
.social-icon + .social-icon {
  margin-left: 5px; /* Adjust the spacing between icons as needed */
}
