.section-box {
    position: relative;
    display: inline-block;
    border: 2px solid transparent;
    border-left: none; /* Hide left border */
    border-top: none; /* Hide top border */
    border-bottom: none; /* Hide bottom border */
    border-right: 2px solid rgb(218, 214, 214)21, 199, 199)98, 185, 185); /* Display only the right border */
    /* padding: 20px; */
    /* margin: 10px; */
    border-radius: 5px;
    background-color:white;
    /* margin-right: 2px; */
    /* margin-left: 50px; */
    padding-left: 20px;
    padding-right: 20px;
    
  }
  

    /* margin-top: 0px; */
    /* margin: 0; */
    /* padding: 0; */
  
/*   
  p {
    margin: 0;
    padding: 0;
  }
   */
   .ecb-class {
    display: flex;
     alignItems: center;
      background:white; 
      color: black;
       padding: 10px; 
       
       padding-right: 120px;
       padding-left: 120px;
   }
   .section-content {
    padding-left: 20px;
   }